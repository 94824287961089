import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'

import security from '../images/services/security.jpg'


import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'



import consulting from '../images/services/new/pragicts-ai-consulting-services.jpg'
import implementation from '../images/services/new/pragicts-ai-implementation-services.jpg'

import openai from '../images/technologies/openai.png'
import claude from '../images/technologies/claude.png'
import gemini from '../images/technologies/gemini.png'
import zapier from '../images/technologies/zaiper.png'
import meta from '../images/technologies/meta.png'




import less from '../images/technologies/tech/less.png'
import sass from '../images/technologies/tech/sass.png'
import foundation  from '../images/technologies/tech/foundation.png'
import react from '../images/technologies/tech/react.png'
import jquery from '../images/technologies/tech/jquery.png'
import python from '../images/technologies/tech/python.png'
import php from '../images/technologies/tech/php.png'
import nodejs from '../images/technologies/tech/node.png'
import laravel from '../images/technologies/tech/laravel.png'
import reactnative from '../images/technologies/tech/reactnative.png'
import mysql from '../images/technologies/tech/mysql.png'
import mongodb from '../images/technologies/tech/mongodb.png'
import mariadb from '../images/technologies/tech/mariadb.png'
import postgresql from '../images/technologies/tech/postgresql.png'
import awsrds from '../images/technologies/tech/awsrds.png'
import redis from '../images/technologies/tech/redis.png'
import sqlite from '../images/technologies/tech/sqlite.png'
import woocommerce from '../images/technologies/tech/woocommerce.png'
import bagisto from '../images/technologies/tech/bagisto.png'
import opencart from '../images/technologies/tech/opencart.png'
import shopify from '../images/technologies/tech/shopify.png'
import strapi from '../images/technologies/tech/strapi.png'
import ghost from '../images/technologies/tech/ghost.png'
import directus from '../images/technologies/tech/directus.png'
import aws from '../images/technologies/tech/aws.png'
import azure from '../images/technologies/tech/azure.png'
import gcp from '../images/technologies/tech/gcp.png'
import chatgpt from '../images/technologies/tech/chatgpt.png'
import bootstrap from '../images/technologies/tech/bootstrap.png'
import vuejs from '../images/technologies/tech/vuejs.png'
import flask from '../images/technologies/tech/flask.png'
import flutter from '../images/technologies/tech/flutter.png'
import prometheus from '../images/technologies/tech-prometheus-a7dcee35e78dc1d81752ef09b419760c.png'
import grafana from '../images/technologies/tech-grafana-8a1edfed75528afa4fd2f82c7c73c299.png'
import linux from '../images/technologies/linux.png'
import hyper from '../images/technologies/hyper.png'
import vbox from '../images/technologies/vbox.png'
import splunk from '../images/technologies/splunk.png'





import angular from '../images/technologies/angular.png'
import apache from '../images/technologies/apache.png'
import argo from '../images/technologies/argo.png'
import bamboo from '../images/technologies/bamboo.png'
import bitbucket from '../images/technologies/bitbucket.png'
import c from '../images/technologies/c#.png'
import cloudflare from '../images/technologies/cloudflare.png'
import cloudformation from '../images/technologies/cloudformation.png'
import css from '../images/technologies/css.png'
import dart from '../images/technologies/dart.png'
import django from '../images/technologies/django.png'
import docker from '../images/technologies/docker.png'
import express from '../images/technologies/express.png'
import f5 from '../images/technologies/f5.png'
import firebase from '../images/technologies/firebase.png'
import git from '../images/technologies/git.png'
import github from '../images/technologies/github.png'
import gitlab from '../images/technologies/gitlab.png'
import gitops from '../images/technologies/gitops.png'
import godaddy from '../images/technologies/godaddy.png'
import golang from '../images/technologies/golang.png'
import gradle from '../images/technologies/gradle.png'
import heml from '../images/technologies/heml.png'
import html from '../images/technologies/html.png'
import java from '../images/technologies/java.png'
import javascript from '../images/technologies/javascript.png'
import jenkins from '../images/technologies/jenkins.png'
import jira from '../images/technologies/jira.png'
import kubernetes from '../images/technologies/kubernetes.png'
import mui from '../images/technologies/mui.png'
import nagios from '../images/technologies/nagios.png'
import net from '../images/technologies/net.png'
import pulumi from '../images/technologies/pulumi.png'
import springboot from '../images/technologies/springboot.png'
import terraform from '../images/technologies/terraform.png'
import typescript from '../images/technologies/typescript.png'
import vscode from '../images/technologies/vscode.png'
const clients = [
    {
        name: 'Open AI (GBT)',
        logo: openai,
        url: 'https://openai.com/'
    },
    {
        name: 'ANTHROPIC Claud',
        logo: claude,
        url: 'https://claude.ai'
    },
    {
        name: 'Google Gemini',
        logo: gemini,
        url: 'https://gemini.google.com/'
    },
    {
        name: 'Zapier Central',
        logo: zapier,
        url: 'https://zapier.com/'
    },
    {
        name: 'Meta AI',
        logo: meta,
        url: 'https://ai.meta.com/meta-ai/'
    },



    {
        name: 'Less',
        logo: less,
        url: 'https://lesscss.org/'
    },
    {
        name: 'Sass',
        logo: sass,
        url: 'https://sass-lang.com/'
    },
    {
        name: 'Foundation',
        logo: foundation,
        url: 'https://get.foundation/'
    },
    {
        name: 'Bootstrap',
        logo: bootstrap,
        url: 'https://getbootstrap.com/'
    },
    {
        name: 'Vuejs',
        logo: vuejs,
        url: 'https://vuejs.org/'
    },
    {
        name: 'React',
        logo: react,
        url: 'https://react.dev/'
    },
    {
        name: 'Jquery',
        logo: jquery,
        url: 'https://jquery.com/'
    },
    {
        name: 'Python',
        logo: python,
        url: 'https://www.python.org/'
    },
    {
        name: 'Php',
        logo: php,
        url: 'https://www.php.net/'
    },
    {
        name: 'Flask',
        logo: flask,
        url: 'https://flask.palletsprojects.com/'
    },
    {
        name: 'Nodejs',
        logo: nodejs,
        url: 'https://nodejs.org/en'
    },
    {
        name: 'Laravel',
        logo: laravel,
        url: 'https://laravel.com/'
    },
    {
        name: 'Flutter',
        logo: flutter,
        url: 'https://flutter.dev/'
    },
    {
        name: 'Reactnative',
        logo: reactnative,
        url: 'https://reactnative.dev/'
    },
    {
        name: 'MySQL',
        logo: mysql,
        url: 'https://www.mysql.com/'
    },
    {
        name: 'Mongodb',
        logo: mongodb,
        url: 'https://www.mongodb.com/'
    },
    {
        name: 'Mariadb',
        logo: mariadb,
        url: 'https://mariadb.org/'
    },
    {
        name: 'postgresql',
        logo: postgresql,
        url: 'https://www.postgresql.org/'
    },
    {
        name: 'AWS RDS',
        logo: awsrds,
        url: 'https://aws.amazon.com/rds/'
    },
    {
        name: 'Redis',
        logo: redis,
        url: 'https://redis.io/'
    },
    {
        name: 'Sqlite',
        logo: sqlite,
        url: 'https://www.sqlite.org/'
    },
    {
        name: 'WooCommerce',
        logo: woocommerce,
        url: 'https://woo.com/'
    },
    {
        name: 'Bagisto',
        logo: bagisto,
        url: 'https://bagisto.com/en/'
    },
    {
        name: 'Opencart',
        logo: opencart,
        url: 'https://www.opencart.com/'
    },
    {
        name: 'Shopify',
        logo: shopify,
        url: 'https://www.shopify.com/'
    },
    {
        name: 'Strapi',
        logo: strapi,
        url: 'https://strapi.io/'
    },
    {
        name: 'Ghost',
        logo: ghost,
        url: 'https://ghost.org/'
    },
    {
        name: 'Directus',
        logo: directus,
        url: 'https://directus.io/'
    },
    {
        name: 'Aws',
        logo: aws,
        url: 'https://aws.amazon.com/'
    },
    {
        name: 'Azure',
        logo: azure,
        url: 'https://azure.microsoft.com/en-us/'
    },
    {
        name: 'GCP',
        logo: gcp,
        url: 'https://cloud.google.com/'
    },
    {
        name: 'Chatgpt',
        logo: chatgpt,
        url: 'https://chat.openai.com/'
    },


    {
        name: 'prometheus',
        logo: prometheus,
        url: 'https://prometheus.io/'
    },
    {
        name: 'grafana',
        logo: grafana,
        url: 'https://grafana.com/'
    },
 
   
  
  
 
   
    {
        name: 'linux',
        logo: linux,
        url: 'https://www.linux.org/'
    },
  
    {
        name: 'hyper',
        logo: hyper,
        url: '#'
    },
    {
        name: 'vbox',
        logo: vbox,
        url: 'https://www.virtualbox.org/'
    },

 
  
  
    {
        name: 'Splunk',
        logo: splunk,
        url: 'https://www.splunk.com/'
    },
 

    {
        name: 'Angular',
        logo: angular,
        url: 'https://angular.io/'
    },
    {
        name: 'Apache',
        logo: apache,
        url: 'https://httpd.apache.org/'
    },
    {
        name: 'Argo',
        logo: argo,
        url: 'https://argo-cd.readthedocs.io/'
    },
    {
        name: 'Bamboo',
        logo: bamboo,
        url: 'https://www.atlassian.com/software/bamboo'
    },
    {
        name: 'Bitbucket',
        logo: bitbucket,
        url: 'https://bitbucket.org/product'
    },
    {
        name: 'C#',
        logo: c,
        url: ''
    },
    {
        name: 'cloudflare',
        logo: cloudflare,
        url: 'https://www.cloudflare.com/'
    },
    {
        name: 'Cloudformation',
        logo: cloudformation,
        url: ''
    },
    {
        name: 'Css',
        logo: css,
        url: ''
    },
    {
        name: 'Dart',
        logo: dart,
        url: 'https://dart.dev/'
    },
    {
        name: 'Django',
        logo: django,
        url: 'https://www.djangoproject.com/'
    },
    {
        name: 'Docker',
        logo: docker,
        url: 'https://www.docker.com/'
    },
    {
        name: 'Express',
        logo: express,
        url: 'https://expressjs.com/'
    },
    {
        name: 'F5',
        logo: f5,
        url: 'https://www.f5.com/'
    },
    {
        name: 'Firebase',
        logo: firebase,
        url: 'https://firebase.google.com/'
    },
    {
        name: 'Git',
        logo: git,
        url: 'https://www.git-scm.com/'
    },
    {
        name: 'Github',
        logo: github,
        url: 'https://github.com/'
    },
    {
        name: 'Gitlab',
        logo: gitlab,
        url: 'https://about.gitlab.com/'
    },
    {
        name: 'Gitops',
        logo: gitops,
        url: ''
    },
    {
        name: 'Godaddy',
        logo: godaddy,
        url: 'https://www.godaddy.com/'
    },
    {
        name: 'Golang',
        logo: golang,
        url: 'https://go.dev/'
    },
    {
        name: 'Gradle',
        logo: gradle,
        url: 'https://gradle.org/'
    },
    {
        name: 'Heml',
        logo: heml,
        url: ''
    },
    {
        name: 'Html',
        logo: html,
        url: ''
    },
    {
        name: 'Java',
        logo: java,
        url: ''
    },
    {
        name: 'Javascript',
        logo: javascript,
        url: ''
    },
    {
        name: 'Jenkins',
        logo: jenkins,
        url: 'https://www.jenkins.io/'
    },
    {
        name: 'Jira',
        logo: jira,
        url: 'https://www.atlassian.com/software/jira'
    },
    {
        name: 'Kubernetes',
        logo: kubernetes,
        url: 'https://kubernetes.io/'
    },
    {
        name: 'mui',
        logo: mui,
        url: 'https://mui.com/'
    },
    {
        name: 'Nagios',
        logo: nagios,
        url: 'https://www.nagios.org/'
    },
    {
        name: '.Net',
        logo: net,
        url: ''
    },
    {
        name: 'Pulumi',
        logo: pulumi,
        url: 'https://www.pulumi.com/'
    },
    {
        name: 'Springboot',
        logo: springboot,
        url: 'https://spring.io/projects/spring-boot'
    },
    {
        name: 'Terraform',
        logo: terraform,
        url: 'https://www.terraform.io/'
    },
    {
        name: 'Typescript',
        logo: typescript,
        url: 'https://www.typescriptlang.org/'
    },
    {
        name: 'Vscode',
        logo: vscode,
        url: 'https://code.visualstudio.com/'
    },
    
]
const SolutionsPage2 = () => {
    return (
        <Layout pageTitle="PragICTS | AI | Services">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>SERVICES</h2>
                   
                                <div className="horizonral-subtitle"><span>SERVICES</span></div>
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
               
                    <section className="dark-bg no-padding">
                        <div className="hidden-info-wrap-bg">
                            <div className="bg-ser">
                            </div>
                            <div className="overlay"></div>
                        </div>
                        <div className="hidden-info-wrap">
                            <div className="hidden-info fl-wrap">
                                <div className="hidden-works-list fl-wrap">
                                    
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={consulting}>
                                  
                                            <div className="hidden-works-item-text">
                                                <h3>AI Consulting</h3>
                                                <p>We provide consulting services to strategize and draw up the road map for enterprise (wide or specific) AI implementations.
                                                The process will include<br/> 
                                                1. Feasibility study and evaluation<br/>
                                                2. Identification and prioritization<br/>
                                                3. Conceptualization<br/>
                                                4. POC deliverables<br/>
                                                5. POC implementation<br/>
                                                6. POC evaluation, and live implementation.<br/><br/>
                                               
                                               <span style={{fontWeight:'bolder',color:'white'}}>Contact us for a demo (<a className='whitelink' href="mailto:ai@pragicts.com">ai@pragicts.com</a>)</span>
                                                </p>
                                   
                                                <div className="clearfix"></div>
                                                <span className="serv-number">01.</span>
                                            
                                            </div>
                                        
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={implementation}>
                                  
                                            <div className="hidden-works-item-text">
                                                <h3>AI Implementation, Management, and Support </h3>
                                                <p>We will manage the entire implementation process from start to completion, including the design and implementation of customer applications (Web and mobile) for the integration of the AI implementation. Data input interfaces and their automation it will also provided as part of the AI implementation.
<br/><br/>
<span style={{fontWeight:'bolder',color:'white'}}>Contact us for a demo (<a className='whitelink' href="mailto:ai@pragicts.com">ai@pragicts.com</a>)</span>
</p>
                                               
                                                <div className="clearfix"></div>
                                                <span className="serv-number">02.</span>
                                            
                                            </div>
                                        
                                    </div>


                                
                       
                                   

                                </div>
                            </div>
                        </div>
                        <div className="fl-wrap limit-box"></div>
                    </section>
                    <section  data-scrollax-parent="true" id="technologies">
            <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }" >Technologies<span>//</span></div>
            <div className="container" >
                <div className="section-title fl-wrap">
                    <h3>Technology Stack</h3>
                    <h2>AI <span>Stack</span></h2>
                 
                </div>
            </div>
        
         
            <div className="fl-wrap">
                <div className="container">
                    <ul className="client-list client-list-white">
                        {clients.map(client => (
                            <li key={client.name}><a href={client.url} target="_blank"><img src={client.logo} className='respimg' alt={client.name} title={client.name} /></a></li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="sec-lines"></div>
        </section>
                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default SolutionsPage2
